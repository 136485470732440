<template>
  <machine-errors-history />
</template>

<script>
const MachineErrorsHistory = () => import('@/components/Machines/MachineErrorsHistory')

export default {
  name: 'MachineErrorsHistoryView',
  components: {
    MachineErrorsHistory
  }
}
</script>
